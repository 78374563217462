@import 'node_modules/bootstrap/scss/bootstrap';
@import '~bootswatch/dist/lux/variables';
@import '~bootswatch/dist/lux/bootswatch';

// Override Boostrap variables
// Import Bootstrap source files from node_modules

body {
  margin: 0;
}

.table {
  border-collapse: inherit;
}

.table > :not(:first-child) {
  border: none;
}

.table > :not(caption) > * > * {
  border: none;
  border-width: 0;
}

a {
  color: #533f03;
  font-weight: bold;
}

.app-container {
  height: 98vh;
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &:after,
  &::before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

#view-gestao-da-agenda #search-submit {
  display: none;
}

.app-container {
  box-sizing: border-box;

  .view-container {
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;

    .card {
      padding: 1rem;
    }

    .view-routes {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }
}

.fullscreen {
  position: fixed;
  top: 100px;
  left: 0px;
  width: 99% !important;
  height: calc(100vh - 110px) !important;
  margin: 5px;
  z-index: 1000;
  padding: 5px 25px 50px 25px !important;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Custom button styles
========================================================================== */

.icon-button > .btn {
  background-color: transparent;
  border-color: transparent;
  padding: 0.5rem;
  line-height: 1rem;

  &:hover {
    background-color: transparent;
    border-color: transparent;
  }

  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Temporary workaround for availity-reactstrap-validation */
.invalid-feedback {
  display: inline;
}

/* other generic styles */

.title {
  font-size: 1.25em;
  margin: 1px 10px 1px 10px;
}

.description {
  font-size: 0.9em;
  margin: 1px 10px 1px 10px;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 2px;
}

.error {
  color: white;
  background-color: red;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.break-word {
  white-space: normal;
  word-break: keep-all;
}

.preserve-space {
  white-space: pre-wrap;
}

/* padding helpers */

@mixin pad($size, $side) {
  @if $size== '' {
    @if $side== '' {
      .pad {
        padding: 10px !important;
      }
    } @else {
      .pad {
        padding-#{$side}: 10px !important;
      }
    }
  } @else {
    @if $side== '' {
      .pad-#{$size} {
        padding: #{$size}px !important;
      }
    } @else {
      .pad-#{$side}-#{$size} {
        padding-#{$side}: #{$size}px !important;
      }
    }
  }
}

@include pad('', '');
@include pad('2', '');
@include pad('3', '');
@include pad('5', '');
@include pad('10', '');
@include pad('20', '');
@include pad('25', '');
@include pad('30', '');
@include pad('50', '');
@include pad('75', '');
@include pad('100', '');
@include pad('4', 'top');
@include pad('5', 'top');
@include pad('10', 'top');
@include pad('20', 'top');
@include pad('25', 'top');
@include pad('30', 'top');
@include pad('50', 'top');
@include pad('75', 'top');
@include pad('100', 'top');
@include pad('4', 'bottom');
@include pad('5', 'bottom');
@include pad('10', 'bottom');
@include pad('20', 'bottom');
@include pad('25', 'bottom');
@include pad('30', 'bottom');
@include pad('50', 'bottom');
@include pad('75', 'bottom');
@include pad('100', 'bottom');
@include pad('5', 'right');
@include pad('10', 'right');
@include pad('20', 'right');
@include pad('25', 'right');
@include pad('30', 'right');
@include pad('50', 'right');
@include pad('75', 'right');
@include pad('100', 'right');
@include pad('5', 'left');
@include pad('10', 'left');
@include pad('20', 'left');
@include pad('25', 'left');
@include pad('30', 'left');
@include pad('50', 'left');
@include pad('75', 'left');
@include pad('100', 'left');

@mixin no-padding($side) {
  @if $side== 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}

@include no-padding('left');
@include no-padding('right');
@include no-padding('top');
@include no-padding('bottom');
@include no-padding('all');

/* end of padding helpers */

.no-margin {
  margin: 0px;
}

@mixin voffset($size) {
  @if $size== '' {
    .voffset {
      margin-top: 2px !important;
    }
  } @else {
    .voffset-#{$size} {
      margin-top: #{$size}px !important;
    }
  }
}

@include voffset('');
@include voffset('5');
@include voffset('10');
@include voffset('15');
@include voffset('30');
@include voffset('40');
@include voffset('60');
@include voffset('80');
@include voffset('100');
@include voffset('150');

.readonly {
  background-color: #eee;
  opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */

a:hover {
  cursor: pointer;
}

.hand {
  cursor: pointer;
}

button.anchor-btn {
  background: none;
  border: none;
  padding: 0;
  align-items: initial;
  text-align: initial;
  width: 100%;
}

a.anchor-btn:hover {
  text-decoration: none;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */

#threadDump .popover,
#healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#form-filter label,
th.hand svg {
  display: none;
}

#healthCheck .popover {
  margin-left: -50px;
}

.health-details {
  min-width: 400px;
}

/* bootstrap 3 input-group 100% width
http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */

.width-min {
  width: 1% !important;
}

.visible-xs,
span.d-none.d-md-inline {
  display: none !important;
}

.navbar {
  text-transform: inherit !important;
}

/* jhipster-needle-scss-add-main JHipster will add new css style */

/*Modificações*/
.card {
  border: 0;
}

.table th {
  padding: 10px 4px;
}

.table td {
  padding: 10px 4px;
}

.table .thead-light th {
  font-style: normal;
  text-transform: none;
  letter-spacing: 0px;
  color: #55595c;
  background-color: #f7f7f9;
  border-color: rgba(0, 0, 0, 0.05) !important;
}

.btn-primary {
  background: #56a1e1;
  border-radius: 12px;
  text-transform: none;
  font-size: 14px;
  padding-top: 0px;
  padding-bottom: 0px;
  max-height: 40px;
}

* {
  font-family: Roboto;
  letter-spacing: 0.03em;
  font-size: 15px;
}

.css-select-control > div {
  border-radius: 10px;
}

.css-select-control-telemedicina > div {
  border-radius: 10px;
  border: 1px solid #e5e5e5;

  div[class*='-ValueContainer'] {
    min-height: 36px !important;
    height: 36px !important;
  }
}

.form-control {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: #ffffff;
  border: 1px solid #cecece !important;
  box-sizing: border-box;
  border-radius: 10px;
}

.form-select {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  height: 36px;
  padding: 0;
  padding-left: 3px;
}

.item-teste {
  border-radius: 10px !important;
  background-color: red;
}

option {
  border-radius: 200px !important;
}

.btn.btn-selected {
  border-radius: 8px;
  border: 1px solid #56a1e1;
  background: #56a1e1;
  color: #fff;
  text-transform: none;
  padding: 0.3rem 0.6rem;
}

.btn.btn-primary {
  border-radius: 8px;
  border: 1px solid #56a1e1;
  background: #fff;
  color: #56a1e1;
  padding: 0.3rem 0.6rem;
  display: inline-flex;
  align-items: center;
}

.btn.btn-primary:hover,
.btn.btn-primary.active {
  border: 1px solid #56a1e1;
  color: #fff;
  background: #56a1e1;
}

.btn.btn-success {
  border-radius: 8px;
  border: 1px solid green;
  background: #fff;
  color: green;
  padding: 0.3rem 0.6rem;
}

.btn.btn-success:hover {
  border: 1px solid green;
  color: #fff;
  background: green;
}

.btn.btn-danger {
  border-radius: 8px;
  border: 1px solid #d23430;
  background: #fff;
  color: #d23430;
  padding: 0.3rem 0.6rem;
}

.btn.btn-danger:hover {
  border: 1px solid #d23430;
  color: #fff;
  background: #d23430;
}

#liberacao-agendamento-data-filtro-container > i {
  float: right;
  margin: 16px 10px -42px 37px;
  z-index: 1000;
  padding: 8px;
}

#liberacao-agendamento-data-filtro {
  border: 0;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  background: transparent;
  height: 40px !important;
  padding-left: 20px;
}

#liberacao-agendamento-data-filtro-container > div {
  width: 100%;
  border: 1px solid rgb(179, 179, 179);
  border-radius: 10px;
  cursor: pointer;
}

.modal-body table #gestao-agenda-data-filtro-container > i {
  float: right;
  margin: 16px 10px -42px 37px;
  z-index: 1000;
  padding: 8px;
}

#gestao-agenda-data-filtro {
  border: 0;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  background: transparent;
  height: 40px !important;
  padding-left: 40px;
  font-size: 15px;
}

#gestao-agenda-data-filtro-container > div {
  width: 100%;
  border: 1px solid #ede5e5;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 1px 2px 1px 1px #ede5e5;
}

.custom-date-container {
  //div pai
  background: #fff;
  width: 100%;
  border: 1px solid #ede5e5;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;

  .custom-date-picker {
    //<DatePicker className="custom-date-picker form-control"
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    border: 0;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    background: transparent;
    padding-left: 10px;
  }
}

#view-gestao-da-agenda input.form-control[type='radio'],
#view-gestao-da-agenda input.form-control[type='checkbox'] {
  width: 18px;
  margin: 5px;
}

#view-gestao-da-agenda .range-date .rc-slider-mark-text {
  padding: 0px 7px;
  border: 1px solid #bfbfbf;
  background-color: white;
  box-shadow: 1px 1px 5px #aaaaaa;
  border-radius: 10px;
}

.table td .btn.btn-primary {
  margin-left: 2px;
}

#search-submit {
  margin-top: -35px;
  z-index: 0;
  border: 0;
  margin-right: 20px;
  height: 30px;
  max-width: 30px;
}

#form-filter .mr-3,
#form-filter .mr-3 .col-md-12 .mr-1 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

#form-filter .mr-3 .col-md-12 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

#form-filter input[type='text'] {
  height: calc(1.4em + 1.4rem);
}

.modal-content {
  background-color: #f9f9f9;
  border-radius: 10px;
}

.modal-content .modal-body {
  padding: 0;
}

.table-list {
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid #e5e5e5 !important;
  margin-bottom: 15px;
}

.jhi-item-count {
  padding-top: 7px;
  color: #9c9c9c;
}

.page-item.active .page-link {
  border-radius: 12px;
  background-color: #56a1e1;
  border-color: #56a1e1;
}

#usuarios-web-sgCliente {
  margin-bottom: 15px;
}

.row-cliente-first-column {
  width: 60%;
  float: left;
}

.row-cliente-sgClienteInstSaude {
  width: 40%;
  float: left;
}

div[class^='kn-column__column___'],
div[class^='kn-column__column___'] * {
  border: 0;
}

div[class^='kn-column__column___']:not(:first-child)
  div[class^='kn-search__search'] {
  visibility: hidden;
}

div[class^='kn-column__column___']:first-child div[class^='kn-search__search'] {
  position: absolute;
  top: 0;
  width: 200%;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  height: 33px;
  z-index: 10000;
}

div[class^='kn-column__column___']:first-child
  div[class^='kn-search__search']
  svg {
  display: none;
}

div[class^='kn-column__column___'] {
  padding-top: 30px;
  color: #55595c;
}

div[class^='kn-selection_status__status___'] {
  color: #55595c;
}

div[class*='kn-list__list___'] {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
  margin-top: -10px;
}

div[class*='kn-multi_select__wrapper___'] {
  display: flex;
  flex-direction: row;
  position: relative;
  background: transparent;
  border: 0;
  color: #55595c;
  overflow: visible;
}

div[class^='kn-unselected_status__status___'] {
  height: 45px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #55595c;
  font-style: italic;
}

div[class^='kn-selection_status__status___'] {
  font-style: italic;
}

div[class^='kn-unselected__list_label___'] {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 14px;
  white-space: nowrap;
}

div[class^='kn-selection_status__clear_all___'] {
  display: none;
}

div[class^='kn-selected_item__selected_item___'] button {
  display: none;
}

div[class*='-ValueContainer'] div[class*='-multiValue'] {
  background-color: #dbebfb;
}

div[class*='-ValueContainer'] {
  height: auto;
}

th#LinkInstitucoes-cell-header,
td#LinkInstitucoes-cell,
th#LinkAgenda-cell-header,
td#LinkAgenda-cell,
th#GestaoAgenda-cell-header,
td#GestaoAgenda-cell {
  text-align: center;
}

div.row-cadastro-cliente-first-column {
  float: left;
  padding: 0 15px;
}

div.row-cadastro-cliente-clLinkLogo {
  width: 35%;
  float: left;
  padding: 0 15px;
}

div.row-cadastro-instituicao-first-column {
  width: 65%;
  float: left;
  padding: 0 15px;
}

div.row-cadastro-instituicao-logo {
  width: 39%;
  float: left;
  padding: 0 15px;
}

div.row-cadastro-administradores-first-column {
  width: 40%;
  float: left;
  padding: 0 15px;
}

div.row-cadastro-administradores-instituicao {
  width: 65%;
  float: left;
  padding: 0 15px;
}

td[rol='many-to-many'] span {
  background-color: #dbebfb;
  border-radius: 2px;
  margin: 4px;
  padding: 5px;
}

.form-group {
  margin-bottom: 0.2rem;
}

.modal-footer {
  border-top: 0;
  margin-top: 15px;
}

.modal-header {
  border-bottom: 0;
}

h4 > span {
  font-size: 16px;
  font-weight: 100;
}

.kn-selected_item__selected_item___1uiiv {
  height: 18px !important;
}

.ReactVirtualized__Grid .kn-item_label__label___2fJ5p {
  font-weight: 600;
  color: #56a1e1;
}

.modal {
  top: 0;
}

.Toastify {
  display: none !important;
}

#grupo-menu-perfil-menu-table-list #perfilMenu-cell span,
#grupo-menu-perfil-menu-table-list #grupoMenu-cell span,
#perfil-menu-rotas-table-list #menuRotas-cell span {
  margin: 2px;
  background: #dbebfb;
  padding: 2px 5px 2px 9px;
  border-radius: 5px;
}

#swal2-html-container {
  overflow: visible;
  z-index: 10000;
}

.tela-paciente {
  background-color: #666666;
  position: absolute;
  left: -1rem;
  right: -1rem;
  top: -1rem;
}
.tela-paciente .tela-emergencial-header {
  background-color: #4f4f4f;
  color: #ffffff;
  height: 7%;
  display: flex;
  align-items: center;
}
.tela-paciente .tela-paciente-header {
  background-color: #4f4f4f;
  color: #ffffff;
  height: 15%;
}
.tela-paciente-header-triagem {
  background-color: #4f4f4f;
  color: #ffffff;
  height: 34%;
}

.tela-paciente .tela-paciente-header > div,
.tela-paciente .tela-paciente-header > div > div.row {
  display: flex;
  align-items: center;
  width: 100%;
}

.tela-paciente .tela-paciente-header span {
  padding-right: 15px;
}

.row-cadastro-instituicao-logo > div {
  padding-right: 0px;
  padding-left: 5px;
}

.row-cadastro-instituicao-logo
  > div:first-child
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(2) {
  /* height: 250px; */
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  padding: 20px;
}

.row-cadastro-instituicao-logo
  > div:first-child
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  label {
  box-sizing: border-box;
  padding: 10px 20px;
  width: 89%;
  position: absolute;
  bottom: 0;
  text-align: center;

  border-radius: 8px;
  border: 1px solid #56a1e1;
  background: #fff;
  color: #56a1e1;
  padding: 0.3rem 0.6rem;
}

div.row.justify-content-center > div.col-md-11 {
  flex: 0 0 97%;
  max-width: 97%;
}

.row-cadastro-instituicao-logo > div:nth-child(2) {
  border-radius: 0px 0px 0px 10px;
  background: white;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 5px;
}

.row-cadastro-instituicao-logo > div:nth-child(3) {
  border-radius: 0px 0px 0px 0px;
  background: white;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 5px;
}

.row-cadastro-instituicao-logo > div:nth-child(4) {
  border-radius: 0px 0px 10px 0px;
  background: white;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 5px;
}

.row-cadastro-instituicao-logo > div:nth-child(4) input {
  width: 95%;
}

.row-cadastro-instituicao-logo
  > div:first-child
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  input {
  display: none;
}

.row-cadastro-instituicao-logo > div:first-child > div > div {
  margin-right: 0px;
  margin-left: -6px;
}

.row-cadastro-instituicao-logo
  > div:first-child
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div
  > div:first-child {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.row-cadastro-instituicao-logo
  .upload__image-wrapper
  button.upload__image-remove {
  position: absolute;
  right: 4px;
  top: 4px;
  border: 0px;
  background: transparent;
}

.row-cadastro-instituicao-logo
  .upload__image-wrapper
  button.upload__image-remove:hover {
  background: #d23430;
}

.row-cadastro-instituicao-logo
  .upload__image-wrapper
  button.upload__image-upload {
  width: 100%;
  margin-top: 10px;
}

.row-cadastro-instituicao-logo
  .upload__image-wrapper
  button.upload__image-remove
  i:before {
  content: '\f1f8';
}

.row-cadastro-instituicao-logo
  > div:first-child
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > a
  img {
  height: auto;
  width: 100%;
  margin-top: -26px;
  max-height: 300px !important;
}

.fa.fa-sort {
  display: none;
}

.kn-column__column___3Iwzx {
  margin-right: 10px;
}

.brand-icon {
  box-shadow: 0 3px #a4a4a4;
}

#view-gestao-da-agenda table th .col-sm-1 {
  margin-top: 14px !important;
}

#view-gestao-da-agenda table th {
  border: 0;
  padding-bottom: 2px;
  padding-top: 2px;
}

#view-gestao-da-agenda table {
  margin-top: 15px;
}

// #view-gestao-da-agenda div:nth-child(5) {
//   text-align: right;
// }
// #view-gestao-da-agenda div:nth-child(5) .btn-secondary {
//   background: transparent;
//   height: 40px !important;
//   padding-left: 20px;
//   font-size: 15px;
//   border: 1px solid #ede5e5;
//   border-radius: 12px;
//   cursor: pointer;
//   box-shadow: 1px 3px 1px 1px #ede5e5;
// }

// #view-gestao-da-agenda div:nth-child(5) .btn-secondary:hover {
//   border: 1px solid #0B64C2;
//   color: #fff;
//   background: #0B64C2;
// }

// #view-gestao-da-agenda .col-sm-4:nth-child(4) :first-child {
//   color: #797979;
// }

// #view-gestao-da-agenda .col-sm-4:nth-child(4) :last-child {
//   padding-top: 8px;
//   color: #9b9c9c;
// }
.agendaRowCheckbox + div {
  margin-left: 40px !important;
}

#view-gestao-da-agenda .row:nth-child(2) {
  padding: 4px 0 0 6px;
  font-size: 16px;
}

#view-gestao-da-agenda table th .row .col-sm-11 {
  padding-top: 10px;
}

#agendaRowCheckbox {
  top: 40% !important;
}

.prev-next {
  padding-top: 8px;
}

.prev-next .btn {
  margin-right: 4px;
}

#view-gestao-da-agenda table {
  background-color: #f9f9f9;
  border-radius: 16px;
}

.agenda-filtro-data i {
  float: left;
  margin: 0 0 -29px 8px;
  z-index: 1000;
  padding-top: 19px;
  padding-left: 10px;
}

#gestao-agenda-data-filtro::placeholder {
  color: #56a1e1;
}

#vip .fa-calendar {
  color: #56a1e1;
}

.cadastro-cliente-new-modal,
.cadastro-cliente-update-modal {
  max-width: 800px !important;
}

.sindrome-gripal-modal {
  max-width: 280px;
  z-index: 1000;
}

.fit-space {
  width: 100%;
  justify-content: center;
}

.basic-text {
  font-family: 'Roboto' !important;
  font-style: 'sans-serif';
  font-size: 0.95em;
  margin-top: 4px;
}

#CartaoValidadeComponent-cell-header {
  width: 20%;
}

#PlanoConvenioComponent-cell-header {
  width: 14%;
}

input[type='checkbox'] {
  height: 17px;
}

#form-filter .mr-1.mt-1.row > div {
  padding: 0;
}

.table-responsive {
  overflow-x: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 1px;
}

.gestao-agenda-container {
  width: 100%;
  height: 35.5px;
}

.text-right {
  text-align: right;
}

.text-right .btn-group .btn-default {
  background-color: #fff0 !important;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.atendimentos-command-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.atendimentos-align-command-bar-text {
  margin: 8px;
}

.checkbox-size {
  width: 17px;
}

.w25 {
  width: 25%;
}

.w75 {
  width: 75%;
}

.atendimento-status-realizado {
  background: #a0ffa8;
  color: #1f1f1f;
  border-radius: 18px;
  padding: 5px;
  border: 1px solid #a0ffa8;
}

.atendimento-status-cancelado {
  background: #ffbfbf;
  color: #1f1f1f;
  border-radius: 18px;
  padding: 5px;
  border: 1px solid #ffbfbf;
}

.atendimento-status-confirmado {
  color: #1f1f1f;
}

.atendimento-status-sem-atendimento {
  color: #8a8a8a;
}

.td-atendimentos td {
  padding: 4px;
}

.table > tbody {
  vertical-align: middle;
}

.btn-group {
  position: relative;
  /* display: block; */
}

.atendimento-block-style {
  padding: 4px;
  width: 100%;
  border: 1px solid #e5e5e5;
}

.ranged-date-picker {
  border-color: hsla(0, 0, 80%, 1);
}

.small-text {
  color: white;
  font-size: 0.8rem;
  font-weight: 700;
}

.smaller-text {
  color: white;
  font-size: 0.7rem;
  font-weight: 700;
}
/* ==========================================================================
  Select styles
========================================================================== */

.select-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select-box .options-container {
  width: 47%;
  position: absolute;
  background: white;
  color: #55595c;
  max-height: 0;
  opacity: 0;
  transition: all 0.4s;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  order: 1;
}

.selected {
  background: white;
  border-radius: 8px;
  margin-bottom: 8px;
  color: #9c9d9e;
  position: relative;
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  order: 0;
}

.selected::after {
  position: absolute;
  height: calc(1.2em + 1.2rem);
  right: 10px;
  top: 5px;
  border: 2px solid #e5e5e5;
  transition: all 0.4s;
}

.select-box .options-container.active {
  max-height: 240px;
  opacity: 1;
  overflow-y: scroll;
  color: #9c9d9e;
}

.select-box .options-container.active + .selected::after {
  top: -6px;
  color: #9c9d9e;
}

.select-box .options-container::-webkit-scrollbar {
  width: 8px;
  background: white;
  border-radius: 0 8px 8px 0;
}

.select-box .options-container::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 0 8px 8px 0;
}

.select-box .option,
.selected {
  padding: 8px 24px;
  color: #9c9d9e;
  cursor: pointer;
}

.select-box .option:hover {
  background: white;
  color: #9c9d9e;
}

.select-box label {
  cursor: pointer;
}

.select-box .option .radio {
  display: none;
}

.arrow {
  border: solid #9c9d9e;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.form-control:focus {
  box-shadow: none;
  outline: none;
  border-color: #e5e5e5;
}

.pointer {
  cursor: pointer;
}

.radius-50 {
  border-radius: 50% !important;
}

.square-30px {
  height: 30px !important;
  width: 30px !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2rem !important;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  z-index: 11000;
}
